@import url("https://fonts.googleapis.com/css?family=Work+Sans");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Lato", sans-serif;
  line-height: 1;
}
body {
  font-family: "Work Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  color: #4d4d4d;

  background-color: #f5f6f8;
}

//Color Variables
$sidebar-background-color: #313443;
$active-sidebar-link-color: #22252e;
$hover-sidebar-link-color: $active-sidebar-link-color;
$active-link-color: #98d7ec;
$tooltip-background-color: $sidebar-background-color;

